<template>
  <!-- 数字通证管理 -->
  <div>
    <el-row style="margin:20px 0">
        <el-col :span="6">
              <el-date-picker
              v-model="formData.time"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
        </el-col>
        <el-col :span="4" style="margin-left:20px">
            <el-input placeholder="请输入UID" v-model="formData.userId"></el-input>
        </el-col>
         <el-col :span="4" style="margin-left: 20px">
        <el-select v-model="formData.genId" placeholder="请选择通证">
          <el-option
            v-for="item in genOptions"
            :key="item.id"
            :label="item.tokenNum"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
        <el-col :span="4" style="margin-left:20px">
             <el-select v-model="formData.status" placeholder="请选择状态">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </el-col>
        <el-col :span="4" style="margin-left:20px">
            <el-button type="primary" @click="search">搜索</el-button>
            <el-button type="primary" @click="rest">重置</el-button>
            <el-button type="success" style="margin: 0 15px" @click="exportData()">生成表格</el-button>
        </el-col>
    </el-row>
    <!-- 内容区域 -->

    <div>
      <el-table :data="form" border style="width: 100%">
        <el-table-column prop="userId" label="UID"  align="center"> </el-table-column>
        <el-table-column prop="genName" label="数字通证名称"  align="center"> </el-table-column>
         <el-table-column prop="price" label="单价"  align="center"> </el-table-column>
        <el-table-column prop="num" label="拨付数量"  align="center"> </el-table-column>
        <el-table-column prop="getNum" label="实际到账数"  align="center"> 
          <!-- <template  v-slot="{row}">
              {{row.num-row.backNum}}
          </template> -->
          
        </el-table-column>
        <el-table-column prop="status" 
        label="状态"  align="center">
            <template v-slot="{row}">
                {{row.status==0?'划拨失败':row.status==1?'待领取':row.status==2?'已领取':row.status==3?'已拒绝':'超时拒绝'}}
            </template>
        </el-table-column>
        <el-table-column prop="createTime" label="时间"  align="center"> </el-table-column>
        <!-- <el-table-column prop="" 
        label="操作"  align="center">
            <template v-slot="{row}">
                <el-button @click='editInfo(row)' type='text'>编辑</el-button>
                <el-button @click='editInfo2(row)' type='text'>通证配置</el-button>
            </template>
        </el-table-column> -->
      </el-table>
    </div>

     <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
export default {
   data(){
    return{
        form:[],
        total:0,
        pageSize:10,
        currentPage:1,
        formData:{
            time:[],
            userId:'',
            status:'',
            genId:''
        },
        // ,0划拨失败 1待领取，2已领取 3已拒绝 4超时拒绝
        options:[
            {label:'划拨失败',value:0},
            {label:'待领取',value:1},
            {label:'已领取',value:2},
            {label:'已拒绝',value:3},
             {label:'超时拒绝',value:4}
        ],
        genOptions:[]
    }
   },
   mounted(){
    this.getTongz()
    this.getList()
   },
   methods:{
    search(){
        this.currentPage=1
        this.getList()
    },
    rest(){
        this.currentPage=1
        this.formData={
            time:[],
            userId:'',
            status:'',
            genId:''
        }
        this.getList()
    },
    getTongz(){
      this.axios.get('/admin/gen/findAll')
      .then(res=>{
        this.genOptions=res.data.data.records
      })
    },
    getList(){
         this.axios
        .get('/admin/agreementRecord/findByUserIdAll', {
          currentPage: this.currentPage,
          pageSize: this.pageSize,
          genId:this.formData.genId,
          status:this.formData.status,
          userId:this.formData.userId,
          startTime: this.formData.time[0],
          endTime: this.formData.time[1],
        }).then(res=>{
            this.form=res.data.data.records
            this.total=res.data.data.total
        })
    },
    addGen(){
         this.$router.push('editGen')
    },
    editInfo(row){
        this.$router.push({path:'editGen',query:{id:row.id}})
    },
    editInfo2(row){
         this.$router.push({path:'genAllocation',query:{genId:row.id}})
    },
    exportData() {
      this.$message.success('生成中，请稍候');
      this.axios.get('/admin/genWater/findByUserIdAllList',{genId:this.formData.genId,
          status:this.formData.status,
          userId:this.formData.userId,
          startTime: this.formData.time[0],
          endTime: this.formData.time[1],}).then(() => {
       
        
        setTimeout(()=>{
            this.axios.get('/admin/genWater/getAgreementRecordAll')
            .then(res=>{
                    let data = [
                        ["UID", "通证名称", "单价","拨付数量","实际到账数量","状态","时间"],
                        
                    ];
                    let arr=res.data.data
                    arr.map((item,i)=>{
                        data.push([item.userId,item.genName,item.price ,item.num,item.getNum,item.status==0?'划拨失败':item.status==1?'待领取':item.status==2?'已领取':item.status==3?'已拒绝':'超时拒绝'
                        ,item.createTime])
                    })
                    // 将数据转换为工作表
                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                
                    // 创建工作簿并添加工作表
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                
                    // 生成Excel文件并导出
                    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                    saveAs(dataBlob, '资金流水.xlsx'); 
            })
        }, 2000);
      })
    },
     // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },
   }
}
</script>

<style lang="scss" scoped>
.onBtn {
  display: flex;
  align-items: center;
  padding: 20px;
}
.btn {
  display: flex;
  justify-content: center;
  div {
    margin: 0 3px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>